import React from 'react';
import './App.css';

const HamburgerMenu = ({ color, width, height, space, animation, duration, timingFunction, delay }) => {
  return (
  <div className="hamburger-menu-container">
    <div
      className={`hamburger-menu ${animation ? 'animate' : ''}`}
      style={{
        '--color': color,
        '--width': `${width}px`,
        '--height': `${height}px`,
        '--space': `${space}px`,
        '--duration': `${duration}s`,
        '--timingFunction': timingFunction,
        '--delay': `${delay}s`
      }}
    >
      <div className="bar1"></div>
      <div className="bar2"></div>
      <div className="bar3"></div>
    </div>
  </div>
  );
};

export default HamburgerMenu;