import React, { Component } from 'react';
import Navigation from './Navigation.js'
import Footer from './Footer.js'
import './App.css';


class About extends Component {
  
  render() {
    return (
      <div>
        <header>
        <img src="/img/hamburger-menu-logo.png" className="logo" alt="Hamburger Menu Generator Logo" />
      <a href="/" className="logo-title">Hamburger Menu Generator</a>
          <nav className="menu">
              <Navigation />
          </nav>
      </header>
         <div className="section">
          <h1>About</h1>
          <div>

            <p>We are a passionate team that aims to build easy to use tools that benefit others.
                <br /> Hamburger Menu Generator was created by developers for developers and non technical users
                that want to avoid the repetitive and challenging work of creating a hamburger menu.
            </p>

            <p>This <b>Hamburger Menu Generator</b> is a tool that allows you to make a hamburger menu in seconds.</p>

            <p>You can customize the hamburger menu according to your needs and even add animation.
            See a live preview as you make changes and copy the generated HTML, CSS, and Javascipt code for your project.</p>
            
            <h2>Click below to see our other generators and tools</h2>

            <p><b>Progress Bar Generator</b> - <a href="https://progressbargenerator.com/" target="_blank">progressbargenerator.com</a> </p>

            <p><b>CSS Circle Generator</b> - <a href="https://csscirclegenerator.com/" target="_blank">csscirclegenerator.com</a> </p>
            
            <p><b>REM to PX Converter</b> - <a href="https://remtopxconverter.com/" target="_blank">remtopxconverter.com</a> </p>

        

            <h2>How to Use the Hamburger Menu Generator</h2>

            <p><b>Step 1: </b>Choose a Color
            <br></br><br></br> Change the color of the bars by selecting a RGB value, 
            HEX value, or using the color wheel.</p>

            <p><b>Step 2:</b> Select a Width and Height for the Hamburger Menu Bar 
            <br></br> <br></br>The width is a value that is a pixels (px) and is the width of the three bars.
            <br></br> The height is a value that is in pixels (px) and is the height of the three bars. </p>
            
            <p><b>Step 3: </b>Add Space Between the Bars
            <br></br><br></br> Add space between the bars by selecting a value for "space".
            This value is in pixels (px).</p>

            <p><b>Step 4:</b> Customize the Bars <br></br><br>
            </br> You can further customize the hamburger menu bars by adding animation when a user clicks. <br /> <br />
              </p>

             <p><b>Step 5:</b> Copy the Generated Code <br></br><br></br>
             Click the "Show Code" to see a section where the HTML, CSS, and Javascript code is generated. <br />Copy the
             code and put the hamburger menu that you've created in your project.
              </p> 

          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;