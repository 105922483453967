import React, { useState } from 'react';
import Navigation from './Navigation.js'
import HamburgerMenu from './HamburgerMenu';
import GeneratedCode from './GeneratedCode';
import Footer from './Footer.js';
import './App.css';

function App() {
  const [color, setColor] = useState('#000000');
  const [width, setWidth] = useState(40);
  const [height, setHeight] = useState(4);
  const [space, setSpace] = useState(10);
  const [animation, setAnimation] = useState(false);
  const [duration, setDuration] = useState(0.3);
  const [timingFunction, setTimingFunction] = useState('ease');
  const [delay, setDelay] = useState(0);
  const [showCode, setShowCode] = useState(false);


const toggleAnimationOptions = () => {
  setAnimation(!animation);
};

  const toggleCodeVisibility = () => {
    setShowCode(!showCode);
  };

  return (
  <div>
    <header>
      <img src="/img/hamburger-menu-logo.png" className="logo" alt="Hamburger Menu Generator Logo" />
      <a href="/" className="logo-title">Hamburger Menu Generator </a>
          <nav className="menu">
              <Navigation />
          </nav>
      </header>
    <div className="container">
      <div>
      <h1>Hamburger Menu Generator</h1>
      <h2>Create a HTML CSS hamburger menu for mobile</h2>
      <HamburgerMenu color={color} width={width} height={height} space={space} animation={animation} duration={duration} timingFunction={timingFunction} delay={delay} />
      {showCode && <GeneratedCode color={color} width={width} height={height} space={space} animation={animation} duration={duration} timingFunction={timingFunction} delay={delay} />}
      <div className="controls">
        <span className="controls-title">Choose a Color </span>
          <label>
            Color:
            <input type="color" value={color} onChange={(e) => setColor(e.target.value)} />
          </label>
          <h3 className="controls-title">Select a Width and Height for the Bars </h3>
          <label>
            Width (px):
            <input type="number" value={width} onChange={(e) => setWidth(Number(e.target.value))} />
          </label>
          <label>
            Height (px):
            <input type="number" value={height} onChange={(e) => setHeight(Number(e.target.value))} />
          </label>
          <span className="controls-title">Add Space Between the Bars </span>
          <label>
            Space (px):
            <input type="number" value={space} onChange={(e) => setSpace(Number(e.target.value))} />
          </label>
          <span className="controls-title">Customize Bars (optional)</span>
          <label>
            Show Animation:
            <input type="checkbox" checked={animation} onChange={toggleAnimationOptions} />
          </label>
      <div className={`animation-options ${animation ? 'visible' : ''}`}>
        {animation && (
          <>
            <label>
              Duration (s):
              <input type="number" step="0.1" value={duration} onChange={(e) => setDuration(Number(e.target.value))} />
            </label>
            <label>
              Timing Function:
              <input type="text" value={timingFunction} onChange={(e) => setTimingFunction(e.target.value)} />
            </label>
            <label>
              Delay (s):
              <input type="number" step="0.1" value={delay} onChange={(e) => setDelay(Number(e.target.value))} />
            </label>
          </>
        )}
      </div> {/* End of Animation Options Div */}
        <button className='button' onClick={toggleCodeVisibility}>
          {showCode ? 'Hide Code' : 'Show Code'}
        </button>
      </div> {/* End of Control Div */}
       
    </div> 
  </div>
  <Footer />
</div>
  );
}


export default App;