import React from 'react';

const GeneratedCode = ({ color, width, height, space, animation, duration, timingFunction, delay }) => {
  const htmlCode = `
<div class="hamburger-menu${animation ? ' animate' : ''}">
  <div class="bar1"></div>
  <div class="bar2"></div>
  <div class="bar3"></div>
</div>
`;

  const cssCode = `
.hamburger-menu {
  width: ${width}px;
  height: calc(${height}px * 3 + ${space}px * 2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.hamburger-menu .bar1,
.hamburger-menu .bar2,
.hamburger-menu .bar3 {
  width: 100%;
  height: ${height}px;
  background-color: ${color};
  transition: all ${duration}s ${timingFunction} ${delay}s;
}

${animation ? `
.hamburger-menu.animate .bar1 {
  transform: translateY(calc(${height}px + ${space}px)) rotate(45deg);
}
.hamburger-menu.animate .bar2 {
  opacity: 0;
}
.hamburger-menu.animate .bar3 {
  transform: translateY(calc(-1 * (${height}px + ${space}px))) rotate(-45deg);
}` : ''}
`;

  const jsCode = `
document.querySelector('.hamburger-menu').addEventListener('click', function() {
  this.classList.toggle('animate');
});
`;

  return (
    <div className="generated-code">
      <h2> Copy the HTML Code Below</h2>
      <pre>
        <code>{htmlCode}</code>
      </pre>
      <h2>Copy the CSS Code Below</h2>
      <pre>
        <code>{cssCode}</code>
      </pre>
      <h2>Copy the JavaScript Code Below</h2>
      <pre>
        <code>{jsCode}</code>
      </pre>
    </div>
  );
};

export default GeneratedCode;